






















/** {@link} https://feathericons.com/ */
import Vue from 'vue';

export default Vue.extend({
  name: 'SvgFlag',
  props: {
    size: {
      type: [Number, String],
      required: false,
      default: 24,
    },
  },
});
